import { fetchAPI } from '@/services/prismic';
import { camelize } from '@/scripts/camelize';

const getMainNavigation = `
query getMainNavigation {
  allMain_navigations {
    edges {
      node {
        cpi_logo
        main_links {
          link_label
          link_url {
            ... on _ExternalLink {
              url
              target
            }
          }
        }
        instagram_url {
          ... on _ExternalLink {
            url
            target
          }
        }
        facebook_url {
          ... on _ExternalLink {
            url
            target
          }
        }
        linkedin_url {
          ... on _ExternalLink {
            url
            target
          }
        }
        youtube_url {
          ... on _ExternalLink {
            url
            target
          }
        }
      }
    }
  }
}
`

const getDictionary = `
query allDictionarys {
  allDictionarys {
    edges {
      node {
        string {
          key
          value
        }
      }
    }
  }
}
`

export const fetchMainNavigationContent = async previewData => {
  const {
    allMain_navigations: {
      edges: [{
        node: data,
      } = {}] = [],
    } = {},
  } = await fetchAPI(getMainNavigation, {
    previewData,
  });


  return camelize(data);
};

export const fetchDictionary = async previewData => {
  const {
    allDictionarys: {
      edges: [{
        node: data,
      } = {}] = [],
    } = {},
  } = await fetchAPI(getDictionary, {
    previewData,
  });


  return camelize(data);
};